<template>
  <body-simple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/3387286_payment_purse_shopping_wallet_icon.png"
          class="h-20 ml-2 inline-block"
        />
        شارژ کیف پول
      </h2>
      <ul class="list-inside list-disc mt-3">
        <li>شارژ بیشتر از 500 هزار تومان 8 درصد شارژ رایگان</li>
        <li>شارژ بیشتر از 1 ملیون تومان 10 درصد شارژ رایگان</li>
        <li>شارژ بیشتر از 3 ملیون تومان 15 درصد شارژ رایگان</li>
        <li>شارژ بیشتر از 5 ملیون تومان 20 درصد شارژ رایگان</li>
      </ul>
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-4/12 sm:pl-3">
          <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
            <div>موجودی فعلی شما :</div>
            <div class="text-xl text-left mt-4">
              {{
                $number.format(
                  $store.state.user.wallet +
                    $store.state.user.bonus +
                    $store.state.user.earn -
                    $store.state.user.debit
                )
              }}
              تومان
            </div>
            <hr class="border-blueGray-200 mt-4" />
            <div class="mt-4">مقدار شارژ درخواستی:</div>
            <div class="text-xl text-left mt-4">
              {{ $number.format(charge.value) || 0 }} تومان
            </div>
            <div class="mt-4">شارژ جایزه :</div>
            <div class="text-xl text-left mt-4">{{ $number.format(bonus) }} تومان</div>
          </div>
        </div>
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 mt-5 sm:pr-3 sm:mt-0">
          <ErrorBox :errors="errors" class="mb-5" />
          <form @submit.prevent="submit">
            <MnrNumber
              title="مبلغ شارژ"
              placeholder="مبلغ"
              before="تومان"
              inputDir="ltr"
              :counter="false"
              v-model="charge.value"
              :disabled="disabaledForm"
              :status="errors?.amount ? 'error' : ''"
              :msg="errors?.amount?.[0]"
            />
            <Button class="mt-5" type="submit" color="cyan" :disabled="disabaledForm">
              بررسی و پرداخت
            </Button>
          </form>
        </div>
        <div class="mt-10 w-full">
          <h2 class="text-xl font-bold mb-10">آخرین تراکنش ها</h2>
          <div class="mb-2" v-for="pay in oldPays" :key="pay.id">
            <div
              class="flex flex-wrap w-full p-2 odd:bg-blueGray-100 even:bg-blueGray-50"
            >
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                {{ pay.id }}
              </div>
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                {{ $number.format(pay.amount) }} تومان
              </div>
              <div class="w-6/12 md:w-4/12 xl:w-4/12 text-center p-2">
                {{ pay.updated_at.fa }}
              </div>
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                <span v-if="pay.status >= 100" class="text-green-500 font-bold">
                  موفق
                </span>
                <span v-else-if="pay.status < 0" class="text-red-500 font-bold">
                  ناموفق
                </span>
                <span v-else class="text-amber-500 font-bold">در انتظار</span>
              </div>
              <div
                class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2"
                v-if="pay.status === null"
              >
                پرداخت کنید
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </body-simple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
// import MnrText from "@/components/mnr/MnrText.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    BodySimple,
    MnrNumber,
    Button,
    ErrorBox,
  },
  data() {
    return {
      firstLoading: true,
      disabaledForm: false,
      errors: {},
      charge: {
        value: 0,
      },
      oldPays: [],
    };
  },
  computed: {
    bonus() {
      if (this.charge.value >= 5000000) return (this.charge.value * 20) / 100;
      else if (this.charge.value >= 3000000) return (this.charge.value * 15) / 100;
      else if (this.charge.value >= 1000000) return (this.charge.value * 10) / 100;
      else if (this.charge.value >= 500000) return (this.charge.value * 8) / 100;
      else return 0;
    },
  },
  mounted() {
    let $this = this;
    $this.$axios
      .get("/api/charges", {
        params: { id: $this.$store.state.user.id },
      })
      .then(function (response) {
        $this.oldPays = response.data;
      })
      .catch(function (error) {
        if (error.status == 406) {
          $this.$router.push("/panel");
        }
      })
      .finally(function () {
        $this.firstLoading = false;
      });
  },
  methods: {
    submit() {
      let $this = this;
      $this.errors = {};

      if ($this.charge.value < 10000) {
        $this.errors.amount = ["مبلغ نباید از 10 هزار تومان کمتر باشد"];
      }

      if (Object.keys($this.errors).length) return;

      $this.disabaledForm = true;

      $this.$axios
        .post("/api/charges", {
          id: $this.$store.state.user.id,
          amount: $this.charge.value,
        })
        .then(function (response) {
          $this.$router.push({
            name: "WalletChargeCheck",
            params: { id: $this.$store.state.user.id, payId: response.data.pay.id },
          });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
          if (error.status == 406) {
            $this.$router.push("/panel");
          }
        });
    },
  },
};
</script>
